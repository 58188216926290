<template>
  <div :id="parent" class="accordion">
    <div v-for="(head, index) in headers" class="card mb-0" style="margin-bottom:0.5rem !important;" :key="index">
      <div :id="head.id" @click="$emit('get',head.id)" class="card-header collapsed custom-card-header" data-toggle="collapse" :data-target="'#'+head.target.replace(/ /g,'-')" aria-expanded="false" :aria-controls="'#'+head.target.replace(/ /g,'-')" style="cursor:pointer">
        <div v-if="headType == 'custom-header'">  
          <div class="box w-100">
                <img class="wd-31" src="/static/svg/Active.svg">
                <h6 class="r-text w-50" style="margin:8px">{{head.text}}</h6>
                <span class="fas fa-chevron-right"></span>
                <div class="w-100">
                  <img class="float-right wd-31 mr-3" src="/static/svg/Delete.svg">
                  <img class="float-right wd-31 mr-3" src="/static/svg/Edit.svg"/>
                  <ar-chip a_class="float-right mr-5">{{head.status}}</ar-chip>
                </div>
          </div>
        </div>
        <div v-else>
          <h6>{{head.text}}</h6>
        </div>
        <!-- If you want to add header, just add anything (XD) but add value to your head prop-->
      </div>
      <center><hr class="hr m-0"/></center>
      <div id="target" class="slow p-0 collapse" :aria-labelledby="headerId" :data-parent="'#'+parent">
        <div class="card-body p-0 default-size">
          <div v-if="body == 'custom-body'" class="row p-4">
            <slot name="body"></slot>
          </div>
          <div v-else>
            <p>{{bodyText}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
/**
 * This component is selfishly created (XD), just add add what you want if you think something is missing.
 */
export default {
  name:"ar-accordion",
  components:{
    ArChip      : () => import('./Chip'),
  },
  props:{
    parent:{
      default:"parent"
    },
    target:{
      default:"target"
    },
    headerId:{
      default:"header-controller"
    },
    headerText:{
      default:"Sample Text"
    },
    headType:{
      default:""
    },
    headText:{
      default:""
    },
    bodyText:{
      default:""
    },
    headers:{
      default:()=>[]
    },
    body:{
      default:""
    }
  }
}
</script>

<style scoped>
  .hr{
    width: 98%;
    margin-bottom:0px !important;
  }
  .default-size{
    min-height: 100px;
    max-height: 200px;
    overflow: hidden auto;

  }
  .custom-card-header{
    border-bottom:none !important;
  }
</style>