<template>
    <div class="animated fadeIn faster">
        <ar-dialog
            v-if="$route.path.includes('/verification') && assignedClientListings.length > 0 && showModal"
            id="lfc-auto-captured"
            heading="Notice"
            sub_heading="LFC may be in the Auto-Capture Request page"
            style="overflow-y: auto;"
            :has_button="false"
            :has_footer="false"
            dialog_style="height: 660px;"
        >
            <template>
                <div class="main-con">
                    <div v-for="(lfc, index) in assignedClientListings" :key="index" class="lfc-con">
                        <div class="lfc-campaign-icon">
                            <img style="height: 50px; width: 50px;"
                                :src="$parent.getImage('campaigns', lfc.campaign?.name)">
                        </div>
                        <div class="lfc-details-con">
                            <div class="campaign-name">
                                <span> {{ lfc.campaign?.name }} </span>
                            </div>
                            <div class="status-id">
                                <span class="lfc-id" 
                                    title="Click to Copy Listing ID"
                                    @click="copytoClipboard(lfc.listing_id)"
                                > {{ lfc.listing_id }} </span>
                                <span class="lfc-status" title="Status"> {{ lfc.status }} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="text-align: left; display: flex; column-gap: 5px;">
                    <input type="checkbox" v-model="doNotShowToday">
                    Do not show this again today.
                </div>
                <div class="btn-con float-right mt-3" style="display: flex; column-gap: 10px">
                    <ar-button outlined @click="autoCaptureRequest()">
                        Go to Auto-capture
                    </ar-button>
                    <ar-button data-dismiss="modal" @click="getYesterdayDate()">
                        Close
                    </ar-button>
                </div>
            </template>
        </ar-dialog>
    </div>
</template>
<script>
    const lazyload = (view) => {
        return () => import(`../layouts/${view}`)
    }
    import axios from '../../axiosMime'
    import { getGui, getGuiSession } from '@/utils/session';
    import ClientListing from '@/models/clientListing'
    import { mapGetters, mapState, mapActions } from 'vuex'
    export default {
        name: 'auto-captured-lfc-modal',
        components: {
            'ar-dialog': lazyload("Dialog"),
            'ar-button': () => import("../layouts/Button")
        },
        watch: {
            'show_clientListings'(val) {
                setTimeout(_ => {
                    if(val && this.assignedClientListings.length) {
                        $('#lfc-auto-captured').modal({ backdrop: 'static', keyboard: false })
                    }
                    else
                        $('#lfc-auto-captured').modal('hide')
                }, 500)
            },
            showModal(val) {
                if(this.lfcYesterdayDate == null && this.lfcYesterdayTime == null) {
                    this.showModal = true
                }
                else this.showModal = false
            }
        },
        data() {
            return {
                showModal: false,
                doNotShowToday: false,
                lfcCurrentDate: null,
                lfcCurrentTime: null,
                lfcYesterdayDate: null,
                lfcYesterdayTime: null,
            }
        },
        async mounted() {
            if(getGuiSession())
                await ClientListing.loadAssignedListings();
            await this.getCurrentDate();
            await this.checkModalShow();
        },
        methods: {
            copytoClipboard(listing_id) {
                const textToCopy = listing_id

                navigator.clipboard.writeText(textToCopy)
                    .then(() => {
                        this.$parent.showSnackbar(`You copied Listing ID: ${textToCopy}`, false, 'success', 'fas fa-check-circle')
                    })
                    .catch(err => {
                        this.$parent.showSnackbar(`Unable to copy. ${err}`, false, 'error', 'fas fa-times-circle')
                    })
            },
            getCurrentDate() {
                var fullDate = new Date();
                var day = fullDate.getDate()
                var hour = fullDate.getHours()
                this.lfcCurrentDate = day
                this.lfcCurrentTime = hour
                localStorage.setItem('lfcCurrentTime', this.lfcCurrentTime)
                localStorage.setItem('lfcCurrentDate', this.lfcCurrentDate)
            },
            getYesterdayDate() {
                if(this.doNotShowToday) {
                    localStorage.setItem('lfcYesterdayTime', this.lfcCurrentTime)
                    localStorage.setItem('lfcYesterdayDate', this.lfcCurrentDate)
                    this.lfcYesterdayTime = this.lfcCurrentTime
                    this.lfcYesterdayDate = this.lfcCurrentDate
                    this.showModal = false
                }
                this.showModal = false
            },
            checkModalShow() {
                let date_today = localStorage.getItem('lfcCurrentDate')
                let time_today = localStorage.getItem('lfcCurrentTime')
                let date_yesterday = localStorage.getItem('lfcYesterdayDate')

                if((date_today !== date_yesterday) && (time_today >= 4)) {
                    this.showModal = true
                    this.doNotShowToday = false
                }
            },
            autoCaptureRequest(){
                this.$router.push({name: 'auto-capture-listing'})
            },
        },
        computed: {
            ...mapState(['show_clientListings']),
            assignedClientListings() {
                return ClientListing.query().with("campaign").where("employee_user_id", getGuiSession()?.id).get();
            },
            access_level() {
                const includes= gui => location.pathname.includes(gui)
                const access = includes('verification') 
                    ? 'ver'
                    : includes('lawfirm')
                        ? 'lawfirm'
                        : 'admin';

                return JSON.parse(localStorage.getItem(access))?.access_level
            },
        }
    }
</script>
<style scoped>
    .main-con {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        height: 430px;
        overflow-y: auto;
    }
    .lfc-con {
        display: flex;
        column-gap: 10px;
        padding: 10px;
        width: 100%;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #cccccc;
        transition: 0.2s ease-in-out;
    }
    .lfc-con:hover {
        background-color: #cccccc;
        transition: 0.2s ease-in-out;
        cursor: pointer;
    }
    .lfc-details-con {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        text-align: left;
    }
    .campaign-name {
        font: normal normal normal 18px/19px Roboto;
        font-weight: 500;
    }
    .status-id {
        display: flex; column-gap: 10px;
    }
    .lfc-id {
        background-color: #2D599E;
        letter-spacing: 1.25px;
        font-size: 12px;
        font-weight: 400;
        padding: 3px;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
        transition: 0.2s ease-in-out;
    }
    .lfc-id:hover {
        background-color: #3E88DA;
        transition: 0.2s ease-in-out;
    }
    .lfc-status {
        background: #f3a17a;
        letter-spacing: 1.25px;
        font-size: 12px;
        font-weight: 400;
        padding: 3px;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
    }
</style>