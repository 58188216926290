<template>
    <!-- <button v-if="!with_icon" type="button" class="btn" :class="btn_class" >{{label}}</button> -->
    <button
        :id ="id"
        :type ="type"
        class ="btn a-btn btn-primary"
        :class ="[{[icon_position]:icon,'a-btn-outline':outlined, 'a-btn-fab': is_fab}, a_class]"
        :disabled ="disabled"
        @click ="$emit('click', $event)"
        @mouseover ="$emit('mouseover')"
        @mouseleave ="$emit('mouseleave')"
    >
        <i v-if="icon && !icon_right" :class="icon" class="icon"></i>
        <slot></slot>
        <i v-if="icon && icon_right" :class="icon" class="icon"></i>
        <span v-if="badge" class="badge badge-danger badge-pill" style="position:absolute;top:-6px;right:2px;border-radius:100%;">{{badge_count}}</span>
    </button>
</template>

<script>
export default {
    name: 'ar-button',
    props: {
        label:{
            type: String,
            default:''
        },
        a_class: {
            type: String,
            default:''
        },
        icon: {
            type: String,
            default:''
        },
        icon_right: {
            type: Boolean,
            default: false
        },
        outlined: {
            type:Boolean,
            default:false
        },
        disabled: {
            type: Boolean,
            default:false
        },
        is_fab: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        badge:{
            default:false
        },
        badge_count:{
            default:0
        },
        id:{
            default:""
        }
    },
    computed: {
        icon_position() {
            return (this.icon_right) ? 'a-btn-icon-right' : 'a-btn-icon-left';
        }
    }
}
</script>

<style scoped>
    .a-btn {
        background-color: #2D599E;
        letter-spacing: 1.25px;
        padding: 6px 20px 6px;
        border-radius: 20px;
        position: relative;
        font-weight: 500;
        font-size:14px;
        opacity: 1;
        border: 0;
    }

    .a-btn:not(.disabled):hover {
        background-color: #3E88DA;
    }

    .a-btn:disabled, .a-btn.disabled {
        background-color: #2D599E 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 3px #00000033;
        border-color: transparent;
        opacity: 0.43;
    }

    /* auto-request */
    .a-btn.btn.inverted {
        background-color: #C2DEF9;
        border-color: #C2DEF9;
        color: #2D599E;
    }
    .a-btn.btn.inverted:hover {
        color: #FFFFFF;
        background-color: #2D599E;
    }
    .a-btn.btn.auto-request-change-status{
        background: #3E88DA;
    }
    .a-btn.btn.auto-request-change-status:hover {
        background: #8bc1ff;
    }
    .a-btn.btn.auto-request-delete-request {
        background-color: #DC3C40;
    }
    .a-btn.btn.auto-request-delete-request:hover {
        background-color: #ff8a8c;
    }
    /* ------------------------ */
    .a-btn.btn.active {
        background-color: #2D599E;
    }

    .a-btn.btn.active {
        background-color: #2D599E;
    }

    .a-btn.btn-success {
        background-color: #3a9c51;
    }

    .a-btn.btn-success:active {
        background-color: #28a745;
    }

    .a-btn.btn-success:disabled, .a-btn.btn-success.disabled {
        background-color: #28a745;
        opacity: 0.8;
    }

    .a-btn.btn-warning {
        background-color: #ff971d;
    }

    .a-btn.btn-warning:not(.disabled):hover {
        background-color: #f88400;
    }

    .a-btn.btn-warning:active {
        background-color: #e6a358;
    }

    .a-btn.btn-warning:disabled, .a-btn.btn-warning.disabled {
        background-color: #e6a358;
        opacity: 0.8;
    }

    .a-btn.btn-invalid {
        background-color: #DC3C40;
    }

    .a-btn.btn-invalid:not(.disabled):hover {
        background-color: rgb(211, 89, 91);
    }

    .a-btn.btn-invalid:active {
        background-color: rgb(179, 80, 82);
    }

    .a-btn.btn-invalid:disabled, .a-btn.btn-invalid.disabled {
        background-color: rgb(179, 80, 82);
        opacity: 0.8;
    }

    .a-btn-outline {
        background: transparent 0% 0% no-repeat padding-box;
        border: 1px solid #2D599E;
        border-radius: 20px;
        color: #2D599E;
        opacity: 1;
    }

    .a-btn-outline:not(.disabled):hover {
        background-color: #C2DEF9;
        border-color: #C2DEF9;
        color: #2D599E;
    }

    .a-btn-outline:disabled, .a-btn-outline .disabled {
        background: transparent;
        border: 1px solid #2D599E;
    }

    .a-btn-text {
        background: transparent 0% 0% no-repeat padding-box;
        color: #2D599E;
        border: 0;
    }

    .a-btn-text:not(.disabled):hover {
        background-color: #C2DEF9;
        color: #2D599E;
    }

    .a-btn-text:not(.disabled).btn-text-invalid {
        color: #DC3C40;
    }

    .a-btn-text:not(.disabled).btn-text-invalid:hover {
        background-color: rgb(211, 89, 91);
        color: #ffff;
    }

    .a-btn-text:disabled, .a-btn-text .disabled {
        background-color: #ADBBD8;
    }

    .a-btn-fab {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        padding: 12px 20px;
        border-radius: 40px;
    }

    .a-btn-fab:hover {
        opacity: 0.8;
    }

    .a-btn .icon {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        font-size: 16px;
        height: 16px;
        top: 50%;
    }
    /* auto-request */
    .a-btn .icon.auto-request{
        font-size: 12px;
        height: 12px;
        width: 12px;
        left: 10px !important;
    }
    /* -------- */
    .a-btn.a-btn-icon-left .icon {
        left: 12px;
    }

    .a-btn.a-btn-icon-right .icon {
        right: 12px;
    }

    .a-btn-icon-left {
        padding-left: 36px;
    }

    .a-btn-icon-right {
        padding-right: 36px;
    }

    .btn-outline-default {
        border: 1px solid #383A3D;
        color: #383A3D;
    }

    .btn-outline-invalid {
        border: 1px solid #DC3C40;
        color: #DC3C40;
    }

    .a-btn-outline.btn-outline-invalid:not(.disabled):hover {
        background-color: rgb(243, 101, 103);
        border-color: rgb(243, 101, 103);
        color: #FFFF;
    }

    .a-btn.btn-light {
        background: #ffff;
    }

    .a-btn.btn-light:hover {
        background: rgb(242, 239, 239);
    }

    .btn-warning {
        background-color: #f88400 !important;
    }

    .btn-warning:hover {
        background-color: #f88400a2 !important;
    }

    /*
    * CM VARIATIONS
    */
    .gui-cm .a-btn.accent {
        background-color: #f3a17a;
    }

    .gui-cm .a-btn.accent:not(.disabled):hover {
        background-color: #3E88DA;
    }

    /*
    * ADMIN VARIATIONS
    */
    .gui-admin .a-btn {
        background-color: #480048;
    }

    .gui-admin .a-btn.btn-invalid {
        background-color: #DC3C40;
    }

    .gui-admin .a-btn.accent {
        background-color: #C04848;
    }

    .gui-admin .a-btn:hover {
        background-color: #880288;
    }

    .gui-admin .a-btn-text {
        background-color: transparent;
        color: #480048;
        border: 0;
    }

    .gui-admin .a-btn-text:not(.disabled):hover {
        background-color: #dfc1df;
        color: #480048;
    }

    .gui-admin .a-btn-text:disabled, .gui-admin .a-btn-text .disabled {
        background-color: #f8cdf8;
    }

    .gui-admin .a-btn-outline {
        background: transparent 0% 0% no-repeat padding-box;
        border: 1px solid #480048;
        border-radius: 20px;
        color: #480048;
        opacity: 1;
    }

    .gui-admin .a-btn-outline:not(.disabled):hover {
        background-color: #880288;
        border-color: #880288;
        color: #ffff;
    }

    .gui-admin .a-btn-outline:disabled, .a-btn-outline .disabled {
        background: transparent;
        border: 1px solid #480048;
    }

    .gui-admin .a-btn-fab.light {
        background-color: #FFFFFF;
    }

    .gui-admin .a-btn-fab.accent {
        background-color: #F07241;
    }

    .gui-admin .a-btn-outline.btn-outline-invalid {
        border: 1px solid #DC3C40;
        color: #DC3C40;
    }

    .gui-admin .a-btn-outline.btn-outline-invalid:not(.disabled):hover {
        background-color: #DC3C40;
        border-color: #DC3C40;
        color: #FFFF;
    }

    .gui-admin .a-btn.without-hover:hover {
        background: none !important;
        text-decoration: underline;
    }
</style>
