<template>
    <div class="a-action-bar">
        <div class="a-header-con" :class="{'selected-header mb-3 animated fadeIn': selected}">
            <template v-if="selected != true">
                <h5 class="a-header-not-selected">
                    <span><strong class="font-weight-normal" v-if="campaign"><img :src="img" :alt="campaign">{{ campaign }}:</strong> <em>
                        <i style="font-style:normal" v-if="$route.name != 'listing' && !$route.path.includes('lawfirm')">Showing all</i> {{ from_count }}-{{ to_count }} out of {{ max_count }} {{ subject }}</em>
                        <slot name="extra"></slot>
                    </span>
                </h5>
                <template v-if="upload_doe">
                    <ar-button outlined icon="fas fa-edit" data-toggle="modal" data-target="#update-doe-modal"> Update Doe</ar-button>
                </template>
                <!-- <ar-select v-else :a_style="'width: 200px; font-size: 14px; color: #383A3D; position:relative; top:-3px;'"
                    :options="options" v-model="selected_option" @input="$emit('input',selected_option)">
                    Select Order
                </ar-select> -->
                <div v-else>
                    <div class="d-flex justify-content-between listing-filters-con">
                        <div>
                            <div class="btn-group mr-2 order-by-buttons" role="group" aria-label="Basic example">
                                <button 
                                    :title="$route.path.includes('lawfirm') ? 'Order by ascending' : 'Order Listing ID by ascending'"
                                    type="button"
                                    class="btn btn-default"
                                    :class="{'active': order_by == 'asc'}"
                                    @click="$emit('asc-click'); order_by = 'asc'"
                                    :disabled="disable_search">
                                    <i class="fas fa-sort-amount-up-alt"></i>
                                </button>
                                <button 
                                    :title="$route.path.includes('lawfirm') ? 'Order by descending' : 'Order Listing ID by descending'"
                                    type="button"
                                    class="btn btn-default"
                                    :class="{'active': order_by != 'asc'}"
                                    @click="$emit('desc-click'); order_by = 'desc'"
                                    :disabled="disable_search">
                                    <i class="fas fa-sort-amount-down"></i>
                                </button>
                            </div>
                        </div>
                        <div>
                            <ar-select 
                                class="d-inline-block mr-2"
                                :a_style="'position: relative; top:-1px; font-size: 14px; color: #383A3D' + $route.path.includes('lawfirm') ? 'width: 181px' : 'width: 160px'"
                                v-model="selected_option"
                                @input="$emit('input',selected_option)"
                                :options="options">
                                {{ $route.path.includes('lawfirm') ? 'Sort by Seller Name' : 'Show 10 listings' }}
                            </ar-select>

                            <template v-if="$route.path.includes('lawfirm')">
                                <ar-textfield
                                    class="d-inline-block"
                                    :a_style="'position: relative; top:-1px; font-size: 14px; color: #383A3D; width: 106px'"
                                    icon="fas fa-th-list"
                                    a_type="number"
                                    v-model="input_page_size"
                                    @keyup.enter.native="$emit('submitPageSize',input_page_size)"
                                    title="Show number of listing(s) (Limit to 1000 listings)"
                                />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <h5 class="a-header d-inline-block m-0">
                        <span><strong>Selected {{ selected_count }} Product</strong></span>
                    </h5>
                    <ar-button @click="$emit('click')" class="ml-4" :outlined="true">{{action_text}} All Rows</ar-button>
                </div>

                <div class="a-selected-buttons">
                    <slot></slot>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import select from './Select'
    import button from './Button'
    import textfield from './Textfield'
    export default {
        name: "ar-action-bar",
        components: {
            'ar-select': select,
            'ar-button': button,
            'ar-textfield': textfield
        },
        props: {
            selected: {
                type: Boolean,
                default: false
            },
            upload_doe: {
                type: Boolean,
                default: false
            },
            options: {
                type: Array,
                default: () => []
            },
            img: {
                type: String,
                default: '/static/svg/Campaign Default Photo.svg'
            },
            campaign: {
                type: String,
                default: ''
            },
            from_count: {
                default: ''
            },
            to_count: {
                default: ''
            },
            max_count: {
                default: ''
            },
            subject: {
                type: String,
                default: ''
            },
            selected_count: {
                default: ''
            },
            action_text:{
                default:"Select"
            },
            disable_search: {
                default: false
            },
            default_order_by: {
                default: ''
            },
            page_size_val: {
                default: ''
            }
        },
        data() {
            return {
                selected_option: 0,
                order_by: this.default_order_by || 'desc',
                input_page_size: this.page_size_val || 100
            }
        },
        methods: {
            emit(value){
                this.$emit('input',value)
            }
        }
    }
</script>

<style>
    .a-action-bar {
        margin-bottom: 10px;
    }

    .a-action-bar .a-header-not-selected {
        position: relative;
        top: -3px;
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
        width: 50%;
    }

    .a-header-not-selected strong {
        font-weight: 500 !important;
        max-width: 61%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
    }
    
    .a-action-bar .a-header {
        position: relative;
        top: 1px;
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
    }

    .a-header-not-selected img,
    .a-action-bar .a-header img {
        height: 40px;
        width: 40px;
        margin-right: 17px;
    }

    .a-header-not-selected span,
    .a-action-bar .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
    }

    .a-header-not-selected,
    .a-action-bar .a-header i {
        font-style: initial;
    }

    .a-header-not-selected em,
    .a-action-bar .a-header em {
        font-style: initial;
        opacity: 0.8;
    }

    .a-action-bar .a-header strong {
        font-weight: 500 !important;
    }

    .a-action-bar .a-header-con {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .selected-header {
        background: #EAF0FC 0% 0% no-repeat padding-box;
        padding: 15px 25px;
    }

    .a-header-con .order-by-buttons button.active,
    .a-header-con .sort-by-buttons button.active {
        background: #C2DEF9;
    }

    .a-selected-buttons {
        width: calc(100% - 515px);
        text-align: right;
    }

    .a-selected-buttons .action-item:last-child {
        margin-right: 0 !important;
    }

    @media screen and (max-width: 1330px) {
        .a-selected-buttons .action-item {
            margin-right: 2.5% !important;
        }
    }
</style>
