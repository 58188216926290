<template>
  <div class="a-banner" :style="`${has_btn ? 'padding-right:222px;' : ''} ${icon ? 'padding-left: 99px;': ''}`" :class="b_class">
      <img v-if="icon" :src="icon" class="icon" alt="icon">
      <h5>{{ heading }}</h5>
      {{ text }}
      <ar-button v-if="has_btn" :outlined="outlined" :a_class="a_class" class="banner-btn" @click="$emit('click')" :disabled="disabled">{{ btn_text }}</ar-button>
  </div>
</template>

<script>
import button from './Button'
export default {
    name: 'ar-banner',
    components: {
        'ar-button': button
    },
    props: {
        heading: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        btn_text: {
            type: String,
            default: ''
        },
        has_btn: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: true
        },
        disabled:{
            default:false
        },
        b_class:{
            default:""
        },
        a_class:{
            default:""
        },
        icon: {
            default: ""
        }
    }
}
</script>

<style>
    .a-banner {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        border       : 1px solid #3E88DA;
        padding      : 15px 30px 15px 30px;
        margin-bottom: 25px;
        position     : relative;
        border-radius: 4px;
        color        : #2D599E;
        text-align   : left;
        min-height   : 87px;
        font-size    : 14px;
        /* max-width    : 100%; */
        min-width: 600px;
    }

    .a-banner h5 {
        font-size: 18px;
    }

    .a-banner .banner-btn {
        -webkit-transform: translateY(-50%);
        transform        : translateY(-50%);
        position         : absolute;
        right            : 30px;
        top              : 50%;
    }

    .bnr-warning {
        background: #ffe8d6 !important;
        border    : 1px solid #ff971d !important;
        color     : #ff971d !important;
    }

    .bnr-success {
        color: #0c7d05 !important;
        background: #e5ffea 0 0 no-repeat padding-box !important;
        border: 1px solid #29b348 !important;
    }

    .bnr-danger {
        color: #e03838 !important;
        background: #f7caca 0 0 no-repeat padding-box !important;
        border: 1px solid #e86a6a !important;
    }

    .a-banner .icon {
        width: 67px;
        position: absolute;
        top: 50%;
        left: 17px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
    }

    /*
    * ADMIN GUI Variation 
    */

    .gui-admin .a-banner {
        color: #480048;
        background: #60184821;
        border-color: #601848;
    }
</style>