<template>
    <div 
        class="card a-card" 
        :class="[{
            'a-card-lg': card_type == 'lg',
            'a-card-md': card_type == 'md',
            'a-card-sm': card_type == 'sm',
            'a-card-smd': card_type == 'smd',
            'a-card-cp': card_type == 'cp',
            'a-card-lmd': card_type == 'lmd',
            'inactive': !active && !deleted,
            'border border-dark bg-secondary': deleted,
            'no-header': !has_heading,
        },rootClass]"
        :style="card_type == 'md' && !card_info.sub_heading ? 'height: 227px;' : ''"
        :id="id"
        @mouseenter="has_hovered_options ? isHovered() : null"
        @mouseleave="has_hovered_options ? resetHovered() : null"
        @click="$emit('card-click')">
        <template v-if="card_type == 'md'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img v-if="has_image" :src="logo" :alt="campaign" onerror="this.src='/static/svg/Admin_Logo.svg'">
                    <h4 class="card-lawfirm" v-if="card_info.sub_heading">{{ card_info.sub_heading }}</h4>
                    <h5 class="card-title" :class="{'smaller': smaller_heading}">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text text-truncate" :class="{'mt-1': smaller_heading}" style="min-height: 16px;">{{ card_info.details }}</p>
                <div class="card-body">
                    <hr class="mt-1 mb-2"/>
                    <table class="table table-borderless" v-if="((card_info || {}).sub_info || []).length">
                        <tbody v-if="((card_info || {}).sub_info || []).length">
                            <tr v-for="(info, index) in card_info.sub_info" :key="index">
                                
                                <td class="header-label text-truncate" v-if="info.title"><img v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{ info.title }}<span v-if="info.value">:</span></td>
                                <td v-if="info.value" class="text-truncate"><b>{{ info.value }}</b></td>
                                <td v-if="info.bullet" class="pl-4 text-truncate"><span style="font-size: 32px; top: 22%; left: 0;" class="position-absolute">•</span>{{ info.bullet }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="sub-details">
                        {{ card_info.sub_details }}
                    </div>
                    <p v-if="card_info.created_at" class="card-text text-right" style="font-size:12px; margin-top:20px">{{ toMoment(card_info.created_at) }}</p>
                </div>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else-if="card_type == 'sm'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img v-if="has_image" :src="logo" alt="image">
                    <h4 v-if="!hasNo_subHeading" class="card-lawfirm">{{ card_info.sub_heading }}</h4>
                    <h5 class="card-title" :class="[{'smaller': smaller_heading},customClass_cardTitle]" :title="card_info.heading">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text">{{ card_info.details }}</p>
                <!-- <img style="position: absolute; top:50%; left: 0" src="/static/svg/case-management-modifications/Approved_complaint icon.svg"> -->
            </div>
            <div class="doc_imgs">
                <slot name="doc_imgs"></slot>
            </div>
            <div class="hovered-options" @click="$emit('hover-click')" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else-if="card_type == 'sb-sm'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img v-if="has_image" :src="logo" alt="image">
                    <h4 class="card-lawfirm">{{ card_info.sub_heading }}</h4>
                    <h5 class="card-title" :title="card_info.heading">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text">{{ card_info.details }}</p>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else-if="card_type == 'smd'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img v-if="has_image" :src="logo" alt="image">
                    <h5 class="card-title" :title="card_info.heading">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text line-clamp">{{ card_info.details }}</p>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else-if="card_type == 'cp'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img v-if="has_image" :src="logo" alt="image">
                    <h5 class="mb-3" :title="card_info.heading">{{ card_info.heading }}</h5>
                </a>
            </div>
            <div class="d-flex justify-content-between mb-2">
                <h4>{{ card_info.current_number }}<span> / {{ card_info.max_number }} {{ card_info.subject }}</span></h4>
                <h3 class="align-self-end" v-if="card_info.progress_percentage">{{ card_info.progress_percentage }}%</h3>
            </div>
            <ar-progress
                size="smd"
                :percent="card_info.progress_percentage"
            ></ar-progress>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else-if="card_type == 'lg'">
            <div class="a-card-header">
                <a href="javascript:void(0);" @click="$emit('click')">
                    <img :src="logo" :alt="card_info.heading">
                    <h4 class="card-lawfirm" v-if="card_info.sub_heading">{{ card_info.sub_heading }}</h4>
                    <h5 class="card-title">{{ card_info.heading }}</h5>
                </a>
                <p class="card-text line-clamp">{{ card_info.details }}</p>
                <div class="card-body">
                    <hr/>
                    <table class="table table-borderless" :class="{ 'd-inline-block w-50': ((card_info || {}).sub_info_2 || []).length }" v-if="((card_info || {}).sub_info || []).length">
                        <tbody>
                            <tr v-for="(info, index) in card_info.sub_info" :key="index">
                                <td class="header-label" v-if="info.title"><img v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{ info.title }}<span v-if="info.value">:</span></td>
                                <td :style="card_info.value_style ? `${card_info.value_style}` : ''" v-if="info.value"><b>{{ info.value }}</b></td>
                                <td v-if="info.bullet" class="pl-4"><span style="font-size: 32px; top: 22%; left: 0;" class="position-absolute">•</span>{{ info.bullet }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table class="table table-borderless d-inline-block w-50" v-if="((card_info || {}).sub_info_2 || []).length">
                        <tbody>
                            <tr v-for="(info, index) in card_info.sub_info_2" :key="index">
                                <td class="header-label" v-if="info.title"><img v-if="info.image" :src="info.image" alt="icon" class="mr-2">{{ info.title }}<span v-if="info.value">:</span></td>
                                <td :style="card_info.value_style ? `${card_info.value_style}` : ''" v-if="info.value"><b>{{ info.value }}</b></td>
                                <td v-if="info.bullet" class="pl-4"><span style="font-size: 32px; top: 22%; left: 0;" class="position-absolute">•</span>{{ info.bullet }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="hovered-options" :class="{'hovered': hovered}">
                <div class="hovered-option-con">
                    <slot name="hovered-options"></slot>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="has_heading" class="a-card-header" @click="$emit('campaign-click')">
                <img v-if="has_image" :src="logo" :alt="campaign">
                <h5 class="card-title">{{ campaign }}</h5>
                <p class="card-text">{{ lawfirm }}</p>
            </div>
            <div class="body-con">
                <div class="img-container">
                    <a href="javascript:void(0);" data-toggle="modal" :data-target="target" @click="$emit('click')">
                        <img :src="preview()" :onerror="`this.src='/static/img/Listing_Broken_Image.png'`" :alt="detail">
                    </a>
                    <div v-if="has_floating_image" class="floating-img">
                        <img :src="logo" :alt="detail">
                    </div>
                </div>
                <div class="card-body">
                    <a :href="seller_url" :target="seller_url != 'javascript:void(0);' || seller_url != '' ? '_blank' : '_self'">
                        <img :src="image" alt="default campaign photo">
                    </a>
                    <a href="javascript:void(0);" data-toggle="modal" :data-target="target" @click="$emit('click')">
                        <h5 class="card-title">{{ seller }}</h5>
                        <p class="card-status color-for-rework">{{ rid }} - {{ status == "Recheck" ? "For Rework" : status }}</p>
                        <p class="card-text line-clamp">{{ detail }}</p>
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import {mapGetters} from 'vuex'
import config from '../../../public/static/theme/conf.json'
export default {
    name: 'ar-card',
    components: {
        ArProgress: () => import('./Progressbar'),
    },
    props: {
        image   : {
            type:String,
            default:'/static/svg/Campaign Default Photo.svg'
        },
        campaign: {
            type:String,
            default:''
        },
        lawfirm: {
            type: String,
            default:''
        },
        logo    : {
            type:String,
            default:'/static/svg/Campaign Default Photo.svg'
        },
        seller  : {
            type:String,
            default:''
        },
        rid     : {
            value: String | Number,
            default:''
        },
        status  : {
            type:String,
            default: ''
        },
        detail  : {
            type:String,
            default:''
        },
        product_image: {
            type: String,
            default: ''
        },
        product_url: {
            type: String,
            default: ''
        },
        target: {
            type: String,
            default: ''
        },
        seller_url: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        card_type: {
            type: String,
            default: ''
        },
        card_info: {
            type: Object,
            default: () => ({})
        },
        has_image: {
            type: Boolean,
            default: true
        },
        has_hovered_options: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: true
        },
        deleted: {
            type: Boolean,
            default: false
        },
        has_heading: {
            type: Boolean,
            default: true
        },
        has_floating_image: {
            type: Boolean,
            default: false
        },
        smaller_heading: {
            type: Boolean,
            default: false
        },
        rootClass:{
            type:Array,
            defualt:()=>[]
        },
        hasNo_subHeading: {
            type: Boolean,
            default: false,
        },
        customClass_cardTitle: {
            type: String,
            default: '',
        },
        document_img: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            hovered_timer: 0,
            hovered: false
        }
    },
    computed: {
        ...mapGetters(['isHolidayTheme', 'themeListingPreview'])
    },
    methods: {
        preview(){
            return this.product_image != null && this.product_image != ''
                ? `${process.env.VUE_APP_URL}/files/${this.product_image}`
                : this.themeListingPreview
        },
        errorPreview() {
            return this.themeListingPreview
        },
        isHovered() {
            this.hovered = true
        },
        resetHovered() {
            clearTimeout(this.hovered_timer)
            this.hovered = false
        },
        toMoment(datetime) {
            return moment(datetime).format('LLL');
        }
    }
}
</script>

<style scoped>
    .a-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        box-shadow: 0px 1px 3px #00000033;
        transition: background .2s ease;
        display: inline-block;
        position: relative;
        border-radius: 4px;
        text-align: left;
        font-size: 14px;
        cursor: pointer;
        height: 419px;
        width: 24%;
        max-width: 320px;
    }

    .a-card.no-header {
        height: 347px;
    }

    .a-card .body-con {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        min-height: 346px;
    }

    .a-card .body-con:hover {
        background: #F5F5F5;
    }

    .a-card.inactive {
        background: #F2C6C7;
        border: 1px solid #DC3C40;
    }

    .a-card .a-card-header {
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        position: relative;
        display:block;
        height: 72px;
        padding: 16px 16px 16px 72px;
    }

    .a-card:not(.a-card-lg):not(.a-card-md):not(.a-card-smd):not(.a-card-cp):not(.a-card-sm) .a-card-header:hover {
        background: #F5F5F5;
    }

    .a-card .a-card-header h5 {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.26px;
        color: #000000DE;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .a-card .a-card-header h5.smaller {
        font-size: 18px;
    }

    .a-card .a-card-header img {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        border-radius: 4px;
        position: absolute;
        height: 40px;
        width: 40px;
        left: 16px;
        top: 50%;
    }

    .img-container {
        /* background: #000000DE; */
        position: relative;
        overflow: hidden;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        height: 194px;
    }

    .img-container img {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        position: absolute;
        /* width: 100%; */
        height: 100%;
        left: 50%;
        top: 50%;
    }

    .img-container .floating-img {
        position: absolute;
        height: 60px;;
        width: 60px;
        border: 4px;
        overflow: hidden;
        left: 10px;
        bottom: 20px;
    }
    

    .a-card .card-body {
        position: relative;
    }

    .a-card .card-body img {
        position: absolute;
        height: 40px;
        width: 40px;
        right: 16px;
        top: 20px;
    }

    .a-card .card-title {
        font-weight: 400 !important;
        color: #000000DE;
        font-size: 24px;
        float: initial;
        width: calc(100% - 42px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: 24px;
    }

    .a-card .card-status {
        letter-spacing: 0.4px;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 12px;
    }

    .a-card .card-text {
        letter-spacing: 0.25px;
        color: #000000;
    }

    .a-card-lg {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 223px;
    }

    .a-card-lg:hover {
        background: #F5F5F5;
    }

    .a-card-lg .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-lg .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-lg .card-text {
        letter-spacing: 0.25px;
        margin-top    : 0;
        color         : #515365;
        font-size     : 14px;
        padding-left: 50px;
    }

    .a-card-lg .a-card-header h5 {
        font-size: 24px;
        padding-left: 50px;
    }

    .a-card-lg .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : 0;
        right            : initial;
        top              : 3px;
    }

    .a-card-lg .card-body {
        position: relative;
        padding: 0;
    }

    .a-card-lg .card-body img {
        position: relative;
        height: 12px;
        width: 12px;
        right: initial;
        top: -1px;
    }

    .a-card-lg table tr {
        border: 0;
    }

    .a-card-lg table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-card-lg table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }

    .a-card-lg .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-lg .a-card-toggle:active,
    .a-card-lg .a-card-toggle:focus {
        outline: none;
    }

    .a-card-lg .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-lg.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        z-index: 2;
    }

    .a-card-lg::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-lg.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-lg.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-lg.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .a-card-md {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 259px;
    }

    .a-card-md:hover {
        background: #F5F5F5;
    }

    .a-card-md .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-md .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-md .card-text {
        letter-spacing: 0.25px;
        margin-top    : 10px;
        color         : #515365;
        font-size     : 14px;
    }

    .a-card-md .a-card-header h5 {
        font-size: 24px;
        width: calc(100% - 47px);
    }

    .a-card-md .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-md .card-body {
        position: relative;
        padding: 0;
    }

    .a-card-md .card-body .sub-details {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.4px;
        color: #515365;
        line-height: 18px;
    }

    .a-card-md .card-body img {
        position: relative;
        height: 12px;
        width: 12px;
        right: initial;
        top: -1px;
    }

    .a-card-md table tr {
        border: 0;
    }

    .a-card-md table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-card-md table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }

    .a-card-md .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-md .a-card-toggle:active,
    .a-card-md .a-card-toggle:focus {
        outline: none;
    }

    .a-card-md .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-md.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        z-index: 2;
    }

    .a-card-md::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-md.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-md.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-md.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .rightsholder-campaign.a-card-md table tr td {
        padding-bottom: 0;
        padding-top: 4px;
    }

    /* .a-card-lmd {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 259px;
    }

    .a-card-lmd:hover {
        background: #F5F5F5;
    }

    .a-card-lmd .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-lmd .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-lmd .card-text {
        letter-spacing: 0.25px;
        margin-top    : 10px;
        color         : #515365;
        font-size     : 14px;
    }

    .a-card-lmd .a-card-header h5 {
        font-size: 24px;
    }

    .a-card-lmd .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-lmd .card-body {
        position: relative;
        padding: 0;
    }

    .a-card-lmd .card-body .sub-details {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 0.4px;
        color: #515365;
        line-height: 18px;
    }

    .a-card-lmd .card-body img {
        position: relative;
        height: 12px;
        width: 12px;
        right: initial;
        top: -1px;
    }

    .a-card-lmd table tr {
        border: 0;
    }

    .a-card-lmd table tr .header-label {
        text-transform: uppercase;
        color         : #515365;
        font-weight   : 400;
    }

    .a-card-lmd table tr td {
        font-weight: 500;
        color      : #141517;
        font-size  : 12px;
        padding    : 7px;
    }

    .a-card-lmd .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-lmd .a-card-toggle:active,
    .a-card-lmd .a-card-toggle:focus {
        outline: none;
    }

    .a-card-lmd .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-lmd.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        z-index: 2;
    }

    .a-card-lmd::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-lmd.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-lmd.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-lmd.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    } */

    .a-card-smd {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 130px;
    }

    .a-card-smd:hover {
        background: #F5F5F5;
    }

    .a-card-smd .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-smd .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : 100%;
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-smd .card-text {
        letter-spacing: 0.25px;
        margin-top    : 20px;
        color         : #515365;
        font-size     : 14px;
    }

    .a-card-smd .a-card-header h5 {
        font-size: 24px;
        width: 84%;
    }

    .a-card-smd .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-smd .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-smd .a-card-toggle:active,
    .a-card-smd .a-card-toggle:focus {
        outline: none;
    }

    .a-card-smd .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-smd.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        z-index: 2;
    }

    .a-card-smd::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-smd.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-smd.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-smd.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .a-card-cp {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 146px;
    }

    .a-card-cp:hover {
        background: #F5F5F5;
    }

    .a-card-cp .a-card-header {
        height: auto;
        padding: 0px 16px 0 34px;
    }

    .a-card-cp .a-card-header h5 {
        font-weight: 500;
        font-size: 14px;
        line-height: 25px;
        color: #53606D9C;
    }

    .a-card-cp .a-card-header img {
        height: 24px;
        width: 24px;
        left: 0;
    }

    .a-card-cp h4 {
        color: #53606D;
        font-size: 28px;
        font-weight: 500;
        letter-spacing: 0.9px;
    }

    .a-card-cp h4 span {
        color: #F07241;
        font-size: 12px;
    }

    .a-card-cp h3 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.45px;
        color: #53606D9C;
    }

    .a-card-sm {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        -webkit-transition: background .2s ease;
        transition: background .2s ease;
        border    : 1px solid transparent;
        text-align: left;
        padding   : 16px;
        overflow: hidden;
        height: 106px;
    }

    .a-card-sm:hover {
        background: #F5F5F5;
    }

    .a-card-sm .a-card-header {
        color      : #000000DE;
        font-weight: 400;
        font-size  : 24px;
        padding    : 0;
    }

    .a-card-sm .card-lawfirm {
        text-transform: none;
        letter-spacing: 0.24px;
        color         : #00000099;
        font-weight   : 500;
        font-size     : 14px;
        min-height    : 15px;
        margin        : 0 0 2px;
        width         : calc(100% - 45px);
        overflow      : hidden;
        white-space   : nowrap;
        text-overflow : ellipsis;
    }

    .a-card-sm .card-text {
        letter-spacing: 0.25px;
        margin-top    : 10px;
        color         : #515365;
        font-size     : 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    .a-card-sm .a-card-header h5 {
        font-size: 24px;
        width: 84%;
    }

    .a-card-sm .a-card-header img {
        -webkit-transform: none;
        transform        : none;
        left             : initial;
        right            : 0;
        top              : 0;
    }

    .a-card-sm .a-card-toggle {
        -webkit-transition: transform .2s ease;
        transition        : transform .2s ease;
        position          : absolute;
        color             : #515365;
        border            : 0;
        background        : transparent;
        font-size         : 16px;
        top               : 48px;
        right             : 0;
    }

    .a-card-sm .a-card-toggle:active,
    .a-card-sm .a-card-toggle:focus {
        outline: none;
    }

    .a-card-sm .a-card-toggle:hover {
        opacity: 0.8;
    }

    .a-card-sm.toggled {
        background   : #EAF0FCFC 0% 0% no-repeat padding-box;
        z-index: 2;
    }

    .a-card-sm::before {
        border: 1px solid transparent;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border-radius: 5px;
        position:absolute;
        content: "";
        height: 0px;
        width: 0px;
        z-index: 0;
        left: 0;
        top: 0;
    }

    .a-card-sm.toggled::before {
        border-color : #2D599E;
        width: 100.15%;
        height: 100.19%;
    }

    .a-card-sm.toggled .card-body {
        background  : #EAF0FCFC 0% 0% no-repeat padding-box;
        display     : block;
    }

    .a-card-sm.toggled .a-card-toggle {
        -webkit-transform: rotate(180deg);
        transform        : rotate(180deg);
        color            : #2D599E;
    }

    .a-card .hovered-options {
        -webkit-transition: .5s ease;
        transition: .5s ease;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 4px;
        position: absolute;
        overflow: hidden;
        max-height: 0;
        width: 100%;
        z-index: 2;
        opacity: 0;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }

    .a-card .hovered-option-con {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
        width: 100%;
        left: 50%;
        top: 50%;
    }

    .a-card .hovered-option-con .actions {
        -webkit-transition: opacity .5s ease;
        transition: opacity .5s ease;
        display: inline-block;
        margin: 0 -1.7%;
        opacity: 1;
    }

    .a-card .hovered-option-con .actions:hover {
        opacity: 0.8;
    }

    .a-card .hovered-options.hovered {
        background-color: rgba(45, 88, 158, 0.171);
        border-color: #2D599E;
        max-height: 100%;
        opacity: 1;
    }

    /*
    *   CM VARIATIONS
    */
    .gui-cm .a-card-sm .card-lawfirm {
        text-transform: uppercase;
        color         : #2D599E;
    }

    /*
    *   ADMIN VARIATIONS
    */
    .gui-admin .a-card {
        width: 300px;
        max-width: 450px;
    }

    .gui-admin .a-card.a-card-lg {
        width: 100%;
    }

    .gui-admin .a-card-sm .card-lawfirm {
        text-transform: uppercase;
        color         : #480048;
    }

    .gui-admin .a-titlebar .a-header-not-selected span {
        font-size: 20px;
    }

    .gui-admin .a-card-smd .card-text {
        color: #C04848;
    }

    .gui-admin .a-card .hovered-options.hovered {
        border-color: #480048;
        background-color: #60184817;
    }
    
    .invalid-card{
        background   : #DC1A1A52 0% 0% no-repeat padding-box;
        border       : 1px solid #DC1A1A;
        border-radius: 4px;
        opacity      : 1;
    }
/* customClass_cardTitle props class */
    .items-class {
        overflow: hidden !important; 
        white-space: unset !important; 
        text-overflow: ellipsis !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 44px;
    }
</style>