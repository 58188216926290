<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 210
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    isHorizontal: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    chartData_computed() {
      let data = this.chartData
      if (!data || data.length == 0) {
        data = this.mock_data_chartData
        console.log(data)
      }
      return data
    },
  },
  data() {
    return {
      mock_data_chartData: {
        labels: [ 'WISH', 'ALIBABA', 'AMAZON', 'EBAY', 'OTHERS' ],
        datasets: [ {
            label: 'Default',
                data: [0, 0, 0, 0, 0],
                backgroundColor: '#C5CFE3',
                borderRadius: 60,
            }, {
                label: 'Settled',
                data: [0, 0, 0, 0, 0],
                backgroundColor: '#F4CCC1',
                borderRadius: 60,
            }, {
                label: 'In Progress',
                data: [0, 0, 0, 0, 0],
                backgroundColor: '#F79497',
                borderRadius: 60,
            }, {
                label: 'Found Listings',
                data: [0, 0, 0, 0, 0],
                backgroundColor: '#99CAFA',
                borderRadius: 60,
        }]
      },
      chartOptions_horizontal: {
        indexAxis: 'y',
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              boxWidth: 20,
              boxHeight: 3,
              font: {
                size: 10
              },
            }
          }
        }
      },
      chartOptions_default: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              boxWidth: 20,
              boxHeight: 3,
              font: {
                size: 10
              },
            }
          }
        }
      }
    }
  }
}
</script>
<template>
  <Bar
    :chart-options="isHorizontal ? chartOptions_horizontal : chartOptions_default"
    :chart-data="chartData_computed"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>
