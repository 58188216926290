import axios from 'axios'

axios.interceptors.response.use(
    response => response, 
    error => {
        const response = error.response;
        if (response && response.status === 401) {
            if (location.pathname.includes("verification") && localStorage.getItem('ver') != null) {
                localStorage.removeItem('ver')
                vm.$router.replace({name:"vernosession"})
            } else if (location.pathname.includes("admin") && localStorage.getItem('admin') != null) {
                localStorage.removeItem('admin')
                vm.$session.remove("admin")
                vm.$session.remove("admin_headers")
                vm.$router.replace({name:"adminnosession"})
            } else if (location.pathname.includes("lawfirm") && localStorage.getItem('lawfirm') != null) {
                localStorage.removeItem("token")
                localStorage.removeItem("headers")
                localStorage.removeItem("lawfirm")
                vm.$router.replace({name:"lawfirmnosession"})
            }
        }
        return Promise.reject(error);
    }
)

export default axios
