<template>
    
    <div class="btn-group btn-group-toggle a-toggle-btn" :class="[{'btn-group-vertical': vertical}, a_class]" :data-toggle="toggled ? 'buttons' : ''">
        <template v-if="options.length">
            <div v-for="(option, index) in options" :key="index">
                <div v-if="option.visible" class="btn" :class="{'active': index == 0}">
                    <img :src="option.img" alt="icon">
                    <h5>{{ option.heading }}</h5>
                    <p>{{ option.details }}</p>
                    <input type="radio" :name="option.name" :id="option.id" :checked="index == 0">
                </div>
            </div>
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </div>
</template>

<script>
    export default {
        name: "ar-toggle-button",
        props: {
            a_class: {
                type: String,
                default: ''
            },
            vertical: {
                type: Boolean,
                default: true
            },
            options: {
                type: Array,
                default: () => []
            },
            toggled:{
                default:true
            }
        },
        data() {
            return {

            }
        }
    }
</script>

<style>
    .a-toggle-btn {
        width: 100%;
        color: #141517;
    }

    .a-toggle-btn.btn-group-vertical .btn {
        border-radius: 4px !important;
        background: transparent;
        margin-bottom: 10px;
        position: relative;
        text-align: left;
        font-weight: 400;
        padding: 14px 12px 12px 57px;
        font-size: 12px;
        height: 64px;
        border: 0; 
    }

    .a-toggle-btn.btn-group-vertical .btn.active
    ,.a-toggle-btn.btn-group-vertical .btn:hover {
        background: #EAF0FCFC;
    }

    .a-toggle-btn h5 {
        letter-spacing: 0.24px;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 1px;
    }

    .a-toggle-btn img {
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        left: 10px;
        top: 50%;
    }
    .a-toggle-btn.btn-group-vertical .status-researched.active {
        background-color: #b7d5f7;
    }
    .a-toggle-btn.btn-group-vertical .status-invalid.active {
        background-color: #f700063b;
    }

    .a-toggle-btn.btn-group-vertical .status-haspo.active {
        /* background-color: #79bd9a59; */
        background-color: #b7d5f7;
    }
/* ---------------------------------------------- */
    .a-toggle-btn.btn-group-vertical .status-monitoring.active {
        background-color: #F3A17A45;
    }
    .a-toggle-btn.btn-group-vertical .status-qualified.active {
        background-color: #b7d5f7;
    }
    /* .a-toggle-btn.btn-group-vertical .status-monitoring.active,
    .a-toggle-btn.btn-group-vertical .status-qualified.active {
        background-color: #b7d5f7;
    } */
/* ---------------------------------------------- */
/* ---------------------------------------------- */
    /* .a-toggle-btn.btn-group-vertical .status-accepted.active,
    .a-toggle-btn.btn-group-vertical .status-submitted.active {
        background-color: #29aba259;
    } */
    
    .a-toggle-btn.btn-group-vertical .status-accepted.active {
        background-color: #DFF6F4;
    }
    .a-toggle-btn.btn-group-vertical .status-submitted.active {
        background-color: #b7d5f7;
    }
/* ---------------------------------------------- */
    .a-toggle-btn.btn-group-vertical .status-rejected.active {
        /* background-color: #f700063b; */
        background-color: #F5686C38;
    }

    .a-toggle-btn.btn-group-vertical .status-reworked.active {
        /* background-color: #383a3d42; */
        background-color: #b7d5f7;
    }

    .a-toggle-btn.btn-group-vertical .status-for-rework.active {
        /* background-color: #adbbd845; */

        /* 10/12/2022 - last used */
        /* background-color: #bec1e0; */
        background-color: #b7d5f7;
    }

    .a-toggle-btn.btn-group-vertical .status-for-commission.active {
        background-color: #2d599e54;
        /* background-color: #b7d5f7; */
    }

    .a-toggle-btn.btn-group-vertical .status-remove-commission.active {
        background-color: #f700063b;
        /* background-color: #b7d5f7; */
    }

    .a-toggle-btn.btn-group-vertical .status-for-internal-review.active {
        /* background-color: #8b9dc3; */
        background-color: #b7d5f7;
    }

    .a-toggle-btn.btn-group-vertical .comm-status {
        display: inline-block;
        margin-right: 1%;
        width: 49%;
    }
    
    .a-toggle-btn.btn-group-vertical .comm-status input {
        visibility: hidden;
    }
</style>